import React, { Component } from 'react';
import { GoogleAuthProvider } from "firebase/auth";
import { auth, useFirebase } from "../config/firebase";
import Button from 'react-bootstrap/Button';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import './App.css';
import SurveyManager from "./SurveyManager";
import { Link, Route, Routes, useSearchParams } from "react-router-dom";
import SurveyPage from "./SurveyPage";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const provider = new GoogleAuthProvider();

export default function App() {
    let [searchParams, setSearchParams] = useSearchParams();
    return (
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/survey/:id" element={<SurveyPage />} />
            <Route path="*" element={<NoMatch/>} />
        </Routes>
    );
}
function Home() {
    const {login, authUser, logout} = useFirebase();
    if (authUser) {
        return <div>
            <div className="logo"></div>
            <div className="header">
                <h3>User {authUser.displayName} is Authenticated</h3>
                <Button onClick={() => logout()}>Logout</Button>
                <SurveyManager />
                <ToastContainer position="top-center"/>
            </div>
        </div>
    }
    return <Container className="p-3">
        <div className="logo"></div>
        <Jumbotron>
            <h1 className="header-text">Create Free Online Surveys</h1>
            <Button onClick={() => login(provider)}>Sign in with Google</Button>
        </Jumbotron>
    </Container>
}

function NoMatch() {
    return (
        <div>
            <h2>Not found</h2>
            <p>
                <Link to="/">Go to the home page</Link>
            </p>
        </div>
    );
}