import './surveyManager.css'
import Survey from './Survey'
import {useState, useEffect} from 'react'
import {collection, query, orderBy, onSnapshot, where} from "firebase/firestore"
import AddSurvey from './AddSurvey'
import { db, useFirebase } from "../config/firebase";
import Button from "react-bootstrap/Button";

function SurveyManager() {
    const { authUser } = useFirebase();
    const [openAddModal, setOpenAddModal] = useState(false);
    const [surveys, setSurveys] = useState([]);

    useEffect(() => {
        const surveyColRef = query(collection(db, "surveys"),
            where("user", "==", authUser.email));
        onSnapshot(surveyColRef, (snapshot) => {
            setSurveys(snapshot.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })));
        });
    },[]);

    return (
        <div className='survey-manager'>
            <header>Survey Manager</header>
            <div className='survey-manager-container'>
                <Button
                    onClick={() => setOpenAddModal(true)}>
                    Add survey +
                </Button>
                <div className='survey-manager-surveys'>
                    {surveys.map((survey) => (
                        <Survey
                            id={survey.id}
                            key={survey.id}
                            surveyId={survey.data.id}
                            active={survey.data.active}
                            questions={survey.data.questions}
                            description={survey.data.description}
                        />
                    ))}
                </div>
            </div>

            {openAddModal &&
                <AddSurvey onClose={() => setOpenAddModal(false)} open={openAddModal}/>
            }
        </div>
    )
}

export default SurveyManager