import Modal from "./Modal"
import './surveyItem.css'
import SurveyQuestion from "./SurveyQuestion";

function SurveyItem({onClose, open, questions, description}) {

    return (
        <Modal modalLable='Survey Item' onClose={onClose} open={open}>
            <div className='survey-item'>
                <p>{description}</p>
                { questions.map((question, i) => {
                    return <SurveyQuestion question={question} key={i} i={i+1}readOnly={true} />;
                })}
            </div>
        </Modal>
    )
}

export default SurveyItem