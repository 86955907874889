import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import {getAuth, signInWithPopup} from "firebase/auth";
import { useEffect, useState, useCallback } from 'react';

const firebaseConfig = {
    apiKey: "AIzaSyC1ZTGb5SP9XflfmfyVeLFn3xJtw6B28no",
    authDomain: "survlee-d4117.firebaseapp.com",
    databaseURL: "https://survlee-d4117-default-rtdb.firebaseio.com",
    projectId: "survlee-d4117",
    storageBucket: "survlee-d4117.appspot.com",
    messagingSenderId: "75944081088",
    appId: "1:75944081088:web:932712e04cbce9a217df25"
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app);

const useFirebase = () => {
    const [authUser, setAuthUser] = useState(auth.currentUser);

    useEffect(() => {
        const unsubscribe = auth
            .onAuthStateChanged((user) => setAuthUser(user))
        return () => {
            unsubscribe()
        };
    }, []);

    const login = useCallback((provider) =>
        signInWithPopup(auth, provider), []);

    const logout = useCallback(() => auth.signOut(), [])

    return { login, authUser, logout }
}


export { db, auth, useFirebase };