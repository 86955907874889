import Modal from "./Modal"
import {useState} from 'react'
import './editSurvey.css'
import { doc, updateDoc } from "firebase/firestore";
import {db, useFirebase} from "../config/firebase";
import Button from "react-bootstrap/Button";
import SurveyQuestion from "./SurveyQuestion";
import * as uuid from "uuid";

function EditSurvey({open, onClose, toEditQuestions, toEditDescription, id}) {
    const { authUser } = useFirebase();
    const [questions, setQuestions] = useState(toEditQuestions);
    const [description, setDescription] = useState(toEditDescription);

    const handleUpdate = async (e) => {
        e.preventDefault()
        const surveyDocRef = doc(db, "surveys", id);
        try {
            await updateDoc(surveyDocRef, {
                questions: questions,
                description: description,
                user: authUser.email
            });
            onClose();
        } catch (err) {
            alert(err);
        }
    }

    const updateQuestion = (q) => {
        let question = questions.find((qu)=> qu.id === q.id);
        if (q.questionType && q.component === "SELECT") {
            question.questionType = q.questionType;
        }
        if (q.questionText && q.component === "TEXT") {
            question.questionText = q.questionText;
        }
    };

    const addQuestion = (q) => {
        const newList = questions.concat({ questionText: "", questionType: "", id: uuid.v4() });
        setQuestions(newList);
    };

    const deleteQuestion = (q) => {
        questions.splice(questions.indexOf((qu)=> qu.id === q), 1);
        const newList = [...questions];
        setQuestions(newList);
    };

    return (
        <Modal modalLable='Edit Survey' onClose={onClose} open={open}>
            <form onSubmit={handleUpdate} className='edit-survey'>
                <textarea placeholder='Enter survey description' onChange={(e) => setDescription(e.target.value)} value={description}></textarea>
                { questions.map(function(question, i) {
                    return <SurveyQuestion question={question} key={i} readOnly={false} onDelete={(q) => deleteQuestion(q)} onChange={(e)=> updateQuestion(e)}/>;
                })}
                <Button onClick={()=> addQuestion()}>Add question +</Button>
                <Button type='submit'>Update</Button>
            </form>
        </Modal>
    )
}

export default EditSurvey