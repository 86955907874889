import Modal from "./Modal"
import {useState} from 'react'
import './addSurvey.css'
import {collection, addDoc, Timestamp} from 'firebase/firestore'
import {db, useFirebase} from "../config/firebase";
import Button from "react-bootstrap/Button";
import * as uuid from "uuid";
import Select from "react-select";
import SurveyQuestion from "./SurveyQuestion";

function AddSurvey({onClose, open}) {
    const { authUser } = useFirebase();
    const [questions, setQuestions] = useState([]);
    const [description, setDescription] = useState('');
    const options = [
        { value: 'AGREE_DISAGREE', label: 'Agree / Disagree' },
        { value: 'YES_NO', label: 'Yes / No' },
        { value: 'SCALE_MULTI_5', label: 'Disagree Strongly / Disagree / Neutral / Agree / Agree Strongly' },
        { value: 'SCALE_1_10', label: 'Scale of 1 to 10'},
        { value: 'TEXT_ANSWER', label: 'Text Answer'}
    ];
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await addDoc(collection(db, "surveys"), {
                description: description,
                questions: questions,
                active: false,
                created: Timestamp.now(),
                id: uuid.v4(),
                user: authUser.email
            });
            onClose();
        } catch (err) {
            console.log(err);
        }
    };

    const updateQuestion = (q) => {
        let question = questions.find((qu)=> qu.id === q.id);
        if (q.questionType && q.component === "SELECT") {
            question.questionType = q.questionType;
        }
        if (q.questionText && q.component === "TEXT") {
            question.questionText = q.questionText;
        }
    };

    const addQuestion = () => {
        const newList = questions.concat({ questionText: "", questionType: "", id: uuid.v4() });
        setQuestions(newList);
    };

    const deleteQuestion = (q) => {
        questions.splice(questions.indexOf((qu)=> qu.id === q), 1);
        const newList = [...questions];
        setQuestions(newList);
    };

    return (
        <Modal modalLable='Add Survey' onClose={onClose} open={open}>
            <form onSubmit={handleSubmit} className='add-survey' name='addSurvey'>
                <textarea
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder='Enter survey description'
                    value={description}></textarea>
                    { questions.map((question, i)=> {
                        return <SurveyQuestion question={question} key={i} readOnly={false} onDelete={(q) => deleteQuestion(q)} onChange={(e)=> updateQuestion(e)}/>;
                    })}
                <Button onClick={()=> addQuestion()}>Add question +</Button>
                <Button type='submit'>Save</Button>
            </form>
        </Modal>
    )
}

export default AddSurvey;