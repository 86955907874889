import './survey.css';
import {
    doc,
    getDoc,
    setDoc,
    deleteDoc,
    where,
    query,
    collection,
    orderBy,
    onSnapshot,
    addDoc, updateDoc, Timestamp
} from "firebase/firestore";
import {db, useFirebase} from "../config/firebase";
import {useEffect, useState} from "react";
import {useParams} from 'react-router-dom'
import Button from "react-bootstrap/Button";
import * as uuid from "uuid";
function SurveyPage() {
    const [survey, setSurvey] = useState([]);
    const [surveyId, setSurveyId] = useState('');
    const [loaded, setLoaded] = useState(false);
    const [answers, setAnswers] = useState([]);
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [questions, setQuestions] = useState([]);
    const params = useParams();
    const id = params.id;
    const questionTypes = [{ id: "AGREE_DISAGREE", value: [{id: "AGREE", label: "Agree"}, {id: "DISAGREE", label: "Disagree"}]},
                           { id: "YES_NO", value: [{id: "YES", label: "Yes"}, {id: "NO", label: "No"}]},
                           { id: "SCALE_MULTI_5", value: [{id: "0", label: "Disagree Strongly"}, {id: "1", label: "Disagree"},
                                   {id: "2", label: "Neutral"}, {id: "3", label: "Agree"}, {id: "4", label: "Agree Strongly"}]},
                           { id: "SCALE_1_10", value: [{id: "1", label: "1"}, {id: "2", label: "2"}, {id: "3", label: "3"},
                                   {id: "4", label: "4"}, {id: "5", label: "5"}, {id: "6", label: "6"},
                                   {id: "7", label: "7"}, {id: "8", label: "8"}, {id: "9", label: "9"},
                                   {id: "10", label: "10"}]},
                           { id: 'TEXT_ANSWER', label: 'Text Answer'}];
    useEffect(() => {
        async function handleQuery() {
            const surveyColRef = query(collection(db, "surveys"),
                where("id", "==", id));
            const unsubscribe = await onSnapshot(surveyColRef, (snapshot) => {
                    setSurvey(snapshot.docs.map(doc => ({
                        id: doc.id,
                        data: doc.data()
                    })));
                    setSurveyId(snapshot.docs.map(doc => (doc.id))[0]);
                    setQuestions(snapshot.docs.map(doc => (doc.data().questions)));
                },
                (error) => {
                    console.log(error);
                });
        }
        handleQuery().then(()=> { setLoaded(true); });
    },[]);

    const handleAnswerQuestion = (id, questionNumber, answerValue, questionType, questionId) => {
        let existingAnswer = answers.find((a)=> a.questionNumber === questionNumber);

        if (existingAnswer) {
            existingAnswer.response = answerValue;
            answers.splice(answers.indexOf((a)=> a.questionNumber === questionNumber), 1);
            const newAnswerList = answers.concat({response: answerValue, questionNumber, questionType,
                id, surveyId,  created: Timestamp.now(), questionId
            });
            setAnswers(newAnswerList);
        } else {
            const newAnswerList = answers.concat({response: answerValue, questionNumber, questionType,
                id, surveyId, created: Timestamp.now(), questionId
            });
            setAnswers(newAnswerList);
        }
    };

    const handleAnswerTextQuestion = (id, questionNumber, answerValue, questionType, questionId) => {
        let existingAnswer = answers.find((a)=> a.questionId === questionId);
        if (existingAnswer) {
            existingAnswer.response = answerValue;
            answers.splice(answers.indexOf((a)=> a.questionId === questionId), 1);
            const newAnswerList = answers.concat({response: answerValue, questionNumber, questionType,
                id, surveyId,  created: Timestamp.now(), questionId
            });
            setAnswers(newAnswerList);
        } else {
            const newAnswerList = answers.concat({response: answerValue, questionNumber, questionType,
                id, surveyId, created: Timestamp.now(), questionId
            });
            setAnswers(newAnswerList);
        }
    };

    const handleSaveSurvey = async () => {
        const surveyDocRef = doc(db, "surveys", surveyId);
        try {
            await addDoc(collection(db, "responses"), {
                answers,
                id: uuid.v4()
            });
        } catch (err) {
            alert(err);
        }
        setSaveDisabled(true);
    };

    if (survey && survey.length > 0) {
        return (
            <div>
            <div className="survey-elements">
                <h1>{ survey.map((s) => ( s.data.active ? s.data.description: "This survey has been deactivated." )) }</h1>
                { survey.map((s) => ( s.data.active && s.data.questions.map((question, number) => (
                    <div key={question.id} className="survey-element">
                        <div><p><b>{ question.questionText }</b></p></div>
                        <div className="survey-question-buttons">
                            <div className="survey-question-button">
                                { questionTypes.map((qt, i) => {
                                    if ( questionTypes[i].id === question.questionType
                                        && question.questionType !== "TEXT_ANSWER" ) {
                                        return questionTypes[i].value.map((val) => {
                                           return (
                                            <Button className={answers.find((a)=> a.response === val.id) ? "active" : ""}
                                                    onClick={()=> handleAnswerQuestion(uuid.v4(), number, val.id, question.questionType, question.id)} key={val.id}>{ val.label }
                                            </Button>
                                           )
                                        });
                                    } else if ( questionTypes[i].id === question.questionType
                                        && question.questionType === "TEXT_ANSWER" ) {
                                        return (
                                            <textarea key={question.id} style={{width: "500px", height: "100px"}} placeholder='Enter answer' name={question.id}
                                                      onBlur={(e) => handleAnswerTextQuestion(uuid.v4(), number, e.target.value, question.questionType, question.id)}></textarea>
                                        )
                                    }
                                }) }
                            </div>
                        </div>
                    </div>
                ))))}
            </div>
                <div className="survey-save" onClick={()=> handleSaveSurvey()}>
                    { survey[0].data.active && (
                        <Button disabled={saveDisabled}>Save</Button>
                    )}
                    { saveDisabled && (
                        <div>Thank you for your response!</div>
                    )}
                </div>
            </div>
        );
    } else if (!survey && loaded) {
        return (
          <div className="survey-elements">
              <h1>Not found!</h1>
          </div>
        );
    }

}

export default SurveyPage