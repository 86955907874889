import './survey.css';

import {db, useFirebase} from "../config/firebase";
import Select from "react-select";
import {useState} from "react";


function SurveyQuestion({i, onChange, question, onDelete, readOnly}) {
    const { authUser } = useFirebase();
    const [questionText, setQuestionText] = useState(question.questionText);
    const [questionType, setQuestionType] = useState(question.questionType);

    const options = [
        { value: 'AGREE_DISAGREE', label: 'Agree / Disagree' },
        { value: 'YES_NO', label: 'Yes / No' },
        { value: 'SCALE_MULTI_5', label: 'Disagree Strongly / Disagree / Neutral / Agree / Agree Strongly' },
        { value: 'SCALE_1_10', label: 'Scale of 1 to 10'},
        { value: 'TEXT_ANSWER', label: 'Text Answer' }
    ];

    if (readOnly) {
    return (
        <div className={"survey-question"}>
            <p><b>Question {i}</b>: {questionText}</p>
            <p><b>Question Type</b>: { options.map((q)=> {
                if (q.value === questionType)
                    return q.label;
            })}</p>
        </div>
    );
    }
    else {
        return (
        <div>
            <div className={"survey-question"}>
                <input type='text' placeholder='Enter question text' name='questionText' onChange={(e) => {
                    onChange({component: "TEXT", id: question.id, questionText: e.target.value});
                    setQuestionText(e.target.value);
                }} value={ questionText } />
                <Select id="questionType" placeholder="Question type"
                        value={options.find((qt) => qt.value === questionType)}
                        onChange={(e) => onChange({component: "SELECT", id: question.id, questionType: e.value})}
                        options={options}/>
                <div className={"survey-question-delete"} onClick={()=>onDelete(question.id)}></div>
            </div>
        </div>
        );
    }
}

export default SurveyQuestion