import './survey.css';
import {useState} from 'react';
import SurveyItem from './SurveyItem';
import EditSurvey from './EditSurvey';
import { doc, updateDoc, deleteDoc} from "firebase/firestore";
import {db, useFirebase} from "../config/firebase";
import Button from "react-bootstrap/Button";
import ToggleButton from 'react-toggle-button';
import SurveyQuestion from "./SurveyQuestion";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Survey({id, surveyId, questions, description, active}) {
    const { authUser } = useFirebase();
    const [checked, setChecked] = useState(active);
    const [open, setOpen] = useState({edit:false, view:false});

    const handleClose = () => {
        setOpen({edit:false, view:false});
    }

    const handleChange = async () => {
        const surveyDocRef = doc(db, "surveys", id);
        try{
            await updateDoc(surveyDocRef, {
                active: !checked
            }).then(() => setChecked(!checked));
        } catch (err) {
            console.log(err);
        }
    }

    const handleDelete = async () => {
        const surveyDocRef = doc(db, "surveys", id);
        try{
            await deleteDoc(surveyDocRef);
        } catch (err) {
            console.log(err);
        }
    }
    return (
        <div className={`survey ${checked ? 'survey-border-color':''}`}>
            <div className='survey-body'>
                <h2>{description}</h2>
                { questions.map((question, i)=> {
                    return <SurveyQuestion question={question} key={i} i={i+1} readOnly={true} />;
                })}
                <div style={{display: "flex", flexDirection: "row-reverse", textShadow: "none",
                    width: "100%", marginTop: "-50px"}}>
                    { checked && (
                       <div style={{position: "absolute", marginTop: "20px"}}>
                           <a href={`/survey/${surveyId}`} target="_blank">Shareable Survey</a>
                           <div onClick={() => {
                               navigator.clipboard.writeText(`https://www.survlee.com/survey/${surveyId}`);
                               toast("Shareable link copied to your clipboard!");
                           }}
                                title="Copy link to your clipboard" style={{cursor: "pointer"}} className="copy-icon"></div>
                       </div>
                    )}
                    <ToggleButton
                        inactiveLabel={"Inactive"}
                        activeLabel={"Active"}
                        value={checked}
                        onToggle={handleChange}
                        containerStyle={{display: "inline-block", width: "75px", zIndex: 0}}
                        trackStyle={{ width: "75px" }}
                        activeLabelStyle={{ width: "50px" }}
                        inactiveLabelStyle={{ width: "50px" }}
                        thumbAnimateRange={[1, 55]} />
                </div>
                <div className='survey-buttons'>
                    <div className='survey-delete-edit'>
                        <Button
                            className='survey-edit-button'
                            onClick={() => setOpen({...open, edit : true})}>
                            Edit
                        </Button>
                        <Button className='survey-delete-button' onClick={handleDelete}>Delete</Button>
                    </div>
                    <Button
                        onClick={() => setOpen({...open, view: true})}>
                        View
                    </Button>
                </div>
            </div>
            {open.view &&
                <SurveyItem
                    onClose={handleClose}
                    questions={questions}
                    description={description}
                    surveyId={surveyId}
                    open={open.view} />
            }

            {open.edit &&
                <EditSurvey
                    onClose={handleClose}
                    toEditQuestions={questions}
                    toEditDescription={description}
                    open={open.edit}
                    id={id} />
            }
        </div>
    )
}

export default Survey